/**
 * Botão subir para o TOPO
 */

$(window).on('load', function(){

    /**
     * Captura se é um dispositivo mobile
     */

    var mobile = false;

    if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
        mobile = true;
    }

    /**
     * Fixa o menu no topo
     */

    $(window).on('scroll', function() {
        if($(window).scrollTop() > 10){
            $("#menuHeader").addClass("fixed");
            $(".menuToogle").not(".topHeader .menuToogle").addClass("darkMenu");
        } else {
            $("#menuHeader").removeClass("fixed");
            $(".menuToogle").not(".topHeader .menuToogle").removeClass("darkMenu");
        }
    });

    /**
     * Fazer o botão de "TOPO" aparecer de acordo com o scroll
     */

    var amountSize = 700;

    $(window).on('scroll', function() {
        if($(window).scrollTop() > amountSize){
            $("a.back-to-top").fadeIn("slow")
        } else {
            $("a.back-to-top").fadeOut("slow");
        }
    });

    /**
     * Efeito de animação ao clicar em TOPO
     */

    $("a.back-to-top").click(function() {
        return $("html, body").animate({
            scrollTop: 0
        }, 500), !1 
    });

    /**
     * Patch de Correção para width 100vh descontando o tamanho da barra de scroll
     */

    function handleWindow() {
        var body = document.querySelector('body');

        if (window.innerWidth > body.clientWidth + 5 && mobile == false) {
            body.classList.add('has-scrollbar');
            body.setAttribute('style', '--scroll-bar: ' + (window.innerWidth - body.clientWidth) + 'px');
        } else {
            body.classList.remove('has-scrollbar');
        }
    }

    handleWindow();

    $(window).on('resize', handleWindow);

    /**
     * Efeito de submenu
     */

    $(".hasChildren > a").on('click', function(e){
        e.preventDefault();
        $(this).toggleClass('open');
        $(this).next("ul").slideToggle('slow');
        return false;
    });

    /**
     * Troca a imagem do menu quando o evento hover ocorre
     */

    var sliderFeature = $('.sliderFeatureMenu')

    sliderFeature.owlCarousel({
        items: 1,
        nav: false,
        mouseDrag: false,
        touchDrag: false,
        dots: false,
        autoplay: false,
        animateOut: 'fadeOut',
        animateIn: 'fadeIn',
        smartSpeed: 1000
    });

    sliderFeature.trigger('to.owl.carousel', [0, 1000]);

    var timeOut;

    $("#menuItems a").not("#menuItems ul a").on('mouseover', function(e){ 
        var slideIndex = $(this).parent().index();
        if(timeOut != null){
            clearTimeout(timeOut);
            timeOut = null;
        }        
        timeOut = setTimeout(function () {
            sliderFeature.trigger('to.owl.carousel', [slideIndex, 1000]);
        }, 500);        
    });

    $("#menuItems a").not("#menuItems ul a").on('mouseout', function(e){ 
        if(timeOut != null){
            clearTimeout(timeOut);
            timeOut = null;
        }        
    });
    
    /**
     * Slider Principal
     */

    var topSlider = $("#topSlider");

    topSlider.owlCarousel({
        items: 1,
        nav: false,
        dots: false,
        loop: true,
        autoplay: true,
        mouseDrag: false,
        autoplayTimeout: 8000,   
        smartSpeed: 1000, 
        animateOut: 'flipOutX',
        animateIn: 'fadeInRight',    
        responsive:{
            0:{
                animateOut: 'slideOutLeft',
                animateIn: 'slideInRight',
                
            },
            720:{
                animateOut: 'flipOutX',
                animateIn: 'fadeInRight',
            }
        }
    }); 

    function posicionaSetasSlider(){
        var larguraSlider = $(".mainImage picture").width();
        var larguraViewWidth = $(window).width();
        var setas = $(".navArrows");
        var larguraSetas = setas.width();
        var resultado = larguraViewWidth - larguraSlider - 15;
        setas.attr('style', 'left: '+resultado+'px');
    }

    posicionaSetasSlider();

    $(".navArrows .right").on('click', function(e){
        e.preventDefault();
        topSlider.trigger('next.owl.carousel');
        return false;
    });

    $(".navArrows .left").on('click', function(e){
        e.preventDefault();
        topSlider.trigger('prev.owl.carousel');
        return false;
    });

    topSlider.on('changed.owl.carousel', function(e){
        var current_slide_in_loop = e.item.index - (e.relatedTarget._clones.length / 2)
        $(".numbers ul").find(".active").removeClass('active');
        $(".numbers ul li").eq($(this).index(current_slide_in_loop)).addClass('active');
    });

    /**
     * Slider de marcas
     */

    var sliderMarcas = $("#sliderMarcas");
    sliderMarcas.owlCarousel({
        items: 1,
        nav: false,
        mouseDrag: false,
        touchDrag: false,
        dots: false,
        autoplay: false,
        animateOut: 'fadeOut',
        animateIn: 'fadeIn',
    });

    $("#sliderNavs li").on('click', function(){
        var index = $(this).index();
        sliderMarcas.trigger('to.owl.carousel', [index, 1000]);
        $("#sliderNavs li a.active").removeClass('active');
        $(this).find('a').addClass('active');
        return false;
    });

    /**
     * Abrir e fechar o menu
     * Funções pra bloquear o scroll quando o menu está aberto
     */

    function preventDefault(e) {
        e = e || window.event;
        if (e.preventDefault)
            e.preventDefault();
        e.returnValue = false;  
    }
    
    function preventDefaultForScrollKeys(e) {
        if (keys[e.keyCode]) {
            preventDefault(e);
            return false;
        }
    }

    function disableScroll() {
        if (window.addEventListener) // older FF
            window.addEventListener('DOMMouseScroll', preventDefault, false);
        document.addEventListener('wheel', preventDefault, {passive: false}); // Disable scrolling in Chrome
        window.onwheel = preventDefault; // modern standard
        window.onmousewheel = document.onmousewheel = preventDefault; // older browsers, IE
        window.ontouchmove  = preventDefault; // mobile
        document.onkeydown  = preventDefaultForScrollKeys;
    }
    
    function enableScroll() {
        if (window.removeEventListener)
            window.removeEventListener('DOMMouseScroll', preventDefault, false);
        document.removeEventListener('wheel', preventDefault, {passive: false}); // Enable scrolling in Chrome
        window.onmousewheel = document.onmousewheel = null; 
        window.onwheel = null; 
        window.ontouchmove = null;  
        document.onkeydown = null;  
    }

    $("#headerMenu .close").on('click', function(e){
        $("#mainMenu").fadeOut();
        enableScroll();
        setTimeout(function(){
            $(".owl-hidden").removeClass('owl-hidden');
        }, 100);
        e.stopPropagation();
        return false;
    });

    $(".menuToogle").not(".pseudoSlider .menuToogle").on('click', function(e){
        $("#mainMenu").css('visibility','visible').hide().fadeIn();
        disableScroll();
        return false;
    });

    /**
     * Efeito de hover da lista de categorias da página Distribuidor
     */

       
    var categoriaHoverTimeout = null;

    $("#categorias a").on('mouseenter', function () {
        console.log("QUE MERDA É ESSA");
        var $this = $(this);
        if(window.innerWidth >= 992){ 
            if(categoriaHoverTimeout != null){
                clearTimeout(categoriaHoverTimeout);
            }
            categoriaHoverTimeout = setTimeout(function(){
                $this.find(".hover").slideDown();
            }, 300);
        }                 
        return false;
    });

    $("#categorias a").on('mouseleave', function () {            
        var $this = $(this);
        if(window.innerWidth >= 992){ 
            $this.find(".hover").slideUp();
        }                 
        return false;
    });
    

    /**
    * Correção da altura do elemento legenda nos argumentos de venda
    */

    var heightFeature = null;

    if(window.innerWidth >= 992){ 
        heightFeature = $("#argumentosVenda .feature img").height();
        $("#argumentosVenda .legenda").height(heightFeature);
    }

    $(window).on('resize', function(){
        if(window.innerWidth >= 992){
            heightFeature = $("#argumentosVenda .feature img").height();
            $("#argumentosVenda .legenda").height(heightFeature);
        }
    });

    /**
     * Correção do posicionamento da imagem no slider em resoluções diferentes
     */

    var widthImgSliderMenuPrincipal = $("#mainMenu .imagemCategoria img");
    var widthPictureSliderMenuPrincipal = $("#mainMenu .imagemCategoria picture");

    if(widthImgSliderMenuPrincipal.width() < widthPictureSliderMenuPrincipal.width()){
        widthPictureSliderMenuPrincipal.attr('style', 'justify-content: flex-start');
    } 

    $(window).on('resize', function(){
        if(widthImgSliderMenuPrincipal.width() < widthPictureSliderMenuPrincipal.width()){
            widthPictureSliderMenuPrincipal.attr('style', 'justify-content: flex-start');
        } 
    });

    /**
     * Menu responsivo da página de distribuidor
     */

    var estadoSubMenu = false;

    $("#distribuidorHeader .topContainer .menuToogle").on('click', function(){
        if(window.innerWidth < 992){
            $("#mainMenuDistribuidor").fadeToggle();
            $("#distribuidorHeader .topContainer .menuToogle, #distribuidorHeader .social").toggleClass('menuResponsiveOpen');
            if(!estadoSubMenu){
                disableScroll();
                estadoSubMenu = true;
            } else {
                enableScroll();
                estadoSubMenu = false;
            }
        }
        return false; 
    });

    /**
     * Slider na página de produtos
     */

    var sliderProdutoSingle = $(".sliderProdutoSingle");

    sliderProdutoSingle.owlCarousel({
        items: 1,
        nav: false,
        //mouseDrag: false,
        //touchDrag: false,
        dots: false,
        autoplay: false
    });

    $(".sliderProdutoSingleThumbnail a").on('click', function(e){
        var order = $(this).attr('data-order');
        sliderProdutoSingle.trigger('to.owl.carousel', [order, 1000]);
        e.preventDefault(); 
        return false;
    });

    /**
     * Controlando a altura do container do segundo bloco de argumentos na página single
     */

    var alturaContainerArgumentosSecundarios = $("#argumentosCarroselVideo .video").height();
    $("#argumentosCarroselVideo .container .row").attr('style', 'min-height: '+alturaContainerArgumentosSecundarios + 'px');


    $(window).on('resize', function(){
        var alturaContainerArgumentosSecundarios = $("#argumentosCarroselVideo .video").height();
        $("#argumentosCarroselVideo .container .row").attr('style', 'min-height: '+alturaContainerArgumentosSecundarios + 'px');
    });

    /**
    * Efeito do carrossel de argumentos secundários
    */

    $timeOut = null;

    $("#carroselArgumentos h3").on('click', function(e){
        $this = $(this);

        if($timeOut != null){
            clearTimeout($timeOut);
        }        

        $timeOut = setTimeout(function(){
            $this.next("p").slideToggle(400);
            $this.toggleClass('open'); 
        }, 200);
    
        return false;           
    });

    $("#carroselArgumentos h3").on('mousedown', function(e){
        e.preventDefault();
        return false;           
    });

    /**
     * Controlando a altura do container do apresentação na página sobre
     */

    var alturaContainerQuemSomosSobre = $("#quemSomos .feature").height();
    $("#quemSomos .container .row").attr('style', 'min-height: '+alturaContainerQuemSomosSobre + 'px');


    $(window).on('resize', function(){
        var alturaContainerQuemSomosSobre = $("#quemSomos .feature").height();
        $("#quemSomos .container .row").attr('style', 'min-height: '+alturaContainerQuemSomosSobre + 'px');
    });

    /**
     * Carrosel de depoimentos
     */

    var carroselDepoimentos = $('.sliderContainerDepoimentos');
    carroselDepoimentos.owlCarousel({
        items: 1,
        nav: false,
        dots: false,
        autoplay: true,
        smartSpeed: 1000,
        loop: true
    });

    $(".arrowNavigationDepoimentos .right").on('click', function(e){
        e.preventDefault();
        carroselDepoimentos.trigger('next.owl.carousel');
        return false;
    });

    $(".arrowNavigationDepoimentos .left").on('click', function(e){
        e.preventDefault();
        carroselDepoimentos.trigger('prev.owl.carousel');
        return false;
    });

    /**
     * Função de ocultamento dos botões de compartilhamento 
     */

    $(window).on('scroll', function(){
        if($("#share").overlaps(".noshare, .postContent img, #newsletter, #main-footer, #mais-baixados").length && window.innerWidth >= 768){
            $("#share").css('opacity', '0');
        } else {
            $("#share").css('opacity', '1');
        }
    });

    /**
     * Posicionando a sidebar de compartilhamentos corretamente
     */

    if(window.innerWidth >= 768){
        var distanciaImagem = $(".postContent img").length > 0 ? $(".postContent img").offset().left : 0;
        var distanciaTexto = $(".postContent p").length > 0 ? $(".postContent p").offset().left : 0;
        var resultado = distanciaImagem + ((distanciaTexto - distanciaImagem)/2);
        $("#share").css('left', resultado + 'px');
    }
    

    $(window).on('resize', function(){
        if(window.innerWidth >= 768){
            var distanciaImagem = $(".postContent img").length > 0 ? $(".postContent img").offset().left : 0;
            var distanciaTexto = $(".postContent p").length > 0 ? $(".postContent p").offset().left : 0;
            var resultado = distanciaImagem + ((distanciaTexto - distanciaImagem)/2);
            $("#share").css('left', resultado + 'px');
        }   
    });

    /**
     * Fixando a sidebar
     */

    $('#sobre').stickySidebar({
        topSpacing: 120,
        bottomSpacing: 60,
        innerWrapperSelector: '.sobreEstePortfolio',
        containerSelector: '#portfolio .container',
    });

    /**
     * Feito de abrir e fechar o formulário de receber material na versão responsiva
     */

    $(".queroMaterial").on('click', function(){        
        $(".right .inner-responsive").slideToggle(700);
        $(".right").toggleClass('open');
        return false;
    });

    $(".right .fechar").on('click', function(){        
        $(".right .inner-responsive").slideToggle(700);
        $(".right").toggleClass('open');
        return false;
    });

    /**
     * Submenu da página Tether Tools
     */

    $("#menuDistribuidor.tetherTools ul li a").not("#menuDistribuidor.tetherTools ul li ul li a").on('click', function(){
        $(this).parent().find("ul").slideToggle();
        return false;
    });
});